import React from 'react';
// import SingleService from '../../../components/SingleService/SingleService';
import SingleService from '../../../components/Multiple Services/multipleServices';


const HomeServices = () => {
   return (
      <>
         <section className="services__area pt-115 pb-80">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                     <div className="section__title section__title-3 text-center mb-90 wow fadeInUp" data-wow-delay=".2s">
                        {/* <span>Construction Services</span> */}
                        <h2>Services we offer</h2>
                     </div>
                  </div>
               </div>
               <div className="row">

                  <SingleService icon="1" title="Project Management " />
                  <SingleService icon="2" title="Project Design " />
                  <SingleService icon="3" title="Construction " />
                  <SingleService icon="4" title="Maintenance and Repairs" />
                  {/* <SingleService icon="5" title="Landscaping" /> */}
                  {/* <SingleService icon="6" title="Fencing" /> */}
                  {/* <SingleService icon="7" title="Civil Works " /> */}
                  {/* <SingleService icon="8" title="Maintenance and Repairs" /> */}

               </div>
            </div>
         </section>
      </>
   );
};

export default HomeServices;