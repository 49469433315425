import React from 'react';
import { Link } from 'react-router-dom';

const SingleService = ({icon,title}) => {
    if (icon==="1") {
        return (
            <>
               <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                  <div className="services__item mb-90">
                     <div className="services__icon mb-35">
                        <img src={`assets/img/icon/services/services-${icon}.png`} alt="services"/>
                     </div>
                     <div className="services__content">
                        <h3><Link to="/servicesDetails">{title}</Link></h3>
                        <p>Overseeing projects, ensuring timely and within budget delivery.
                           Managing specific aspects of projects, such as scheduling, 
                           budgeting and quality control.
                        </p>
                     </div>
                  </div>
               </div>
            </>
         );

        
    }
    else if (icon==="2") {
        return (
            <>
               <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                  <div className="services__item mb-90">
                     <div className="services__icon mb-35">
                        <img src={`assets/img/icon/services/services-${icon}.png`} alt="services"/>
                     </div>
                     <div className="services__content">
                        <h3><Link to="/servicesDetails">{title}</Link></h3>
                        <p>Architectural 
Structural/ Civil Engineering
Electrical engineering 
Mechanical Engineering 
Quantity Surveying 

                        </p>
                     </div>
                  </div>
               </div>
            </>
         );
    }
    else if (icon==="3") {
        return (
            <>
               <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                  <div className="services__item mb-90">
                     <div className="services__icon mb-35">
                        <img src={`assets/img/icon/services/services-${icon}.png`} alt="services"/>
                     </div>
                     <div className="services__content">
                        <h3><Link to="/servicesDetails">{title}</Link></h3>
                        <p>Pre-construction services: assisting with planning, budgeting and scheduling
Site Preparation: clearing and preparing sites for construction
Building Construction : Constructing new structures inclusive of residential, Offices and industrial. 
Renovations and Remodelling of old structures
Landscaping 
Fencing 
Civil Works

                        </p>
                     </div>
                  </div>
               </div>
            </>
         );
    }

    else if (icon==="4") {
        return (
            <>
               <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                  <div className="services__item mb-90">
                     <div className="services__icon mb-35">
                        <img src={`assets/img/icon/services/services-${icon}.png`} alt="services"/>
                     </div>
                     <div className="services__content">
                        <h3><Link to="/servicesDetails">{title}</Link></h3>
                        <p>Repairs and restoration work of  dilapidated, damaged assets 

                        </p>
                     </div>
                  </div>
               </div>
            </>
         );
    }
    else if (icon==="5") {
        return (
            <>
               <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                  <div className="services__item mb-90">
                     <div className="services__icon mb-35">
                        <img src={`assets/img/icon/services/services-${icon}.png`} alt="services"/>
                     </div>
                     <div className="services__content">
                        <h3><Link to="/servicesDetails">{title}</Link></h3>
                        <p>Constructing new structures inclusive of residential, Offices and industrial. 

                        </p>
                     </div>
                  </div>
               </div>
            </>
         );
    }
    else if (icon==="6") {
        return (
            <>
               <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                  <div className="services__item mb-90">
                     <div className="services__icon mb-35">
                        <img src={`assets/img/icon/services/services-${icon}.png`} alt="services"/>
                     </div>
                     <div className="services__content">
                        <h3><Link to="/servicesDetails">{title}</Link></h3>
                        <p>Constructing new structures inclusive of residential, Offices and industrial. 

                        </p>
                     </div>
                  </div>
               </div>
            </>
         );
    }
    else if (!title) {
        title = 'Expense reporting';
    }
    else if (!title) {
        title = 'Expense reporting';
    }
    else if (!title) {
        title = 'Expense reporting';
    }
   return (
      <>
         <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
            <div className="services__item mb-90">
               <div className="services__icon mb-35">
                  <img src={`assets/img/icon/services/services-${icon}.png`} alt="services"/>
               </div>
               <div className="services__content">
                  <h3><Link to="/servicesDetails">{title}</Link></h3>
                  <p>TBD</p>
               </div>
            </div>
         </div>
      </>
   );
};

export default SingleService;